.testimonial {

	.tiles-item-inner {
		background: get-color(dark, 2);		
	}
}

.testimonial-item-content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 18px;
        margin-top: 12px;
        margin-bottom: 16px;

    }
}
header.minting-block-title {
    color: get-color(primary,1) !important;
    font-size: larger;
}
.testimonial-item-footer {
    font-weight: 600;
    padding-top: 20px;

    &.has-top-divider {

        &::before {
            background: get-color(dark, 3);
        }
    }
}

.testimonial-item-link {
	color: get-color(secondary, 3);

	a {
        color: get-color(secondary, 3);
        text-decoration: none;

        &:hover {
            color: get-color(secondary, 2);
        }
	}
}

.mintButton {
  background-color: get-color(primary,1) !important;
  color: get-color(dark,1) !important;
  left: 10px;
}
.mintNumber  {
  width: 75px;

}

.mintNumber * {
  color: get-color(primary,1) !important;
  font-size: 24px !important;
  border-color: get-color(primary,1) !important;
  height: 48px;
  font-weight: bold !important;
}