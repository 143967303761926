body{
	overflow-x: hidden;
	// background-color: rgb(200, 103, 58);
	// background-image: url('../../../images/laligalandBg.png');
  touch-action: pan-y;
	font-family: sans-serif;
}

a{
	color:#000 !important;
}

.bezel-effect {
	box-shadow: inset -0.2em -0.2em 0.2em 0 rgba(255, 255, 255, 0.5),
	inset 0.2em 0.2em 0.2em 0 rgba(0, 0, 0, 0.5);
	background-color: rgba(255, 88, 11, 0.3);
}

.is-boxed {
    background: color-bg(body-outer);

	.body-wrap {
		max-width: $container--width-boxed;
	    margin: 0 auto;
	    box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: color-bg(body);
			z-index: -10;
		}
	}
}

button.my-custom-crossmint-button {
	background-color: #000;
	border-radius: 50px;
	padding: .6em 1em;
}

.grid-div {
	width: 70%;
	margin: auto;
	display: flex;
	flex-direction: row;
}

.body-wrap {
	position: relative;
    // Sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 35px !important;
		line-height: 36px !important;
  }
	.MuiGrid-item {
		padding-left: 0 !important;
	}
}

@media screen and (max-width: 1025px) {
	button.my-custom-crossmint-button {
		font-size: 22px;
	}
}

